<template>
  <div>
    <h2 class="title is-3">Events I go to</h2>
    <p>Below you can find for which events in the future and past you have signed up for.</p>
    <hr />
    <!-- <div class="mb-5 navbar-custom-menu">
      <div class="navbar-item">
        <a
          class="navbar-link is-arrowless is-unselectable is-size-7-mobile"
          :class="{ 'is-active' : isUpComingEvents }"
          @click="showUpcoming()"
        >Future events</a>
      </div>
      <div class="navbar-item">
        <a
          class="navbar-link is-arrowless is-unselectable is-size-7-mobile"
          :class="{ 'is-active' : !isUpComingEvents }"
          @click="showUpcoming(false)"
        >Past events</a>
      </div>
    </div> -->

    <Message
      v-if="!isLoading && noEventFoundMessage"
      class="is-info"
    >
      {{noEventFoundMessage}}
    </Message>

    <div v-if="!noEventFoundMessage">
      <template v-for="(events, key, index) in filteredEvents">
        <div
          :key="'t_'+key+index"
          class="mt-4 title is-4 is-uppercase"
        >
          {{createHeader(key)}}
        </div>
        <div
          class="columns columns-events is-multiline is-mobile"
          :key="key"
        >

          <div
            class="column is-half"
            v-for="(eventRegistration, index) in events"
            :key="index"
          >
            <EventRegistrationTile :eventRegistration="eventRegistration" />
          </div>
        </div>
        <hr
          class="separator"
          v-if="index < (Object.keys(events).length -1)"
          :key="index"
        />
      </template>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isLoading"
        key="loader"
        class="has-text-centered"
      >
        <img src="@/assets/img/s2m-loader-big.gif" />
      </div>
      <!-- <a
          key="loadButton"
          v-if="!isLoading && morePages"
          class="button is-primary"
          @click="loadMore()"
        >
          Load more events
        </a> -->
    </transition>
  </div>
</template>

<script>
import eventProvider from '../../providers/event'
import Message from '../UI/Message.vue'
import EventRegistrationTile from './EventRegistrationTile.vue'

export default {
  components: {
    EventRegistrationTile,
    Message
  },

  data() {
    return {
      isLoading: true,
      isUpComingEvents: true,
      showOnlyFinalEvents: true,
      eventRegistations: []
    }
  },

  computed: {
    noEventFoundMessage() {
      if (Object.keys(this.filteredEvents).length === 0 && this.isUpComingEvents) {
        return 'No upcoming events were found that you signed up for.'
      }
      if (Object.keys(this.filteredEvents).length === 0 && !this.isUpComingEvents) {
        return 'No past events have been found for which you have signed up for.'
      }
      return ''
    },

    filteredEvents() {
      if (this.eventRegistations.length === 0) { return {} }
      let _eventRegistations = this.eventRegistations
      if (this.showOnlyFinalEvents) {
        _eventRegistations = this.eventRegistations.filter(evt => evt.RegistrationStatus === 'Final')
      }
      return this.processResponse(_eventRegistations)
    }
  },

  created() {
    // this.resetPageState()
    this.getAttendingEvents()
  },

  methods: {
    /**
     * Get events
     */
    showUpcoming(showComing = true) {
      if (this.isLoading) { return }
      // this.resetPageState()
      this.isUpComingEvents = showComing
      if (showComing) {
        this.getAttendingEvents()
      } else {
        this.getVisitedEvents()
      }
    },

    /**
     * Load more events
     */
    loadMore() {
      this.pageState.page++
      if (this.showComing) {
        this.getAttendingEvents()
      } else {
        this.getVisitedEvents()
      }
    },

    /**
     * Get user upcoming events
     */
    getAttendingEvents() {
      let self = this
      this.isLoading = true

      eventProvider.methods.getEventsProfileAttends().then(response => {
        this.eventRegistations = response.data.Results
        // if (response.status === 200) {
        //   self.morePages = response.data.MorePages
        //   if (self.morePages === 0) {
        //     self.pageState.reachedEnd = true
        //   }
        //   self.pageState.events = self.pageState.events.concat(response.data.Results)
        //   self.$store.dispatch('updateEventsPageState', self.pageState)
        // }
      }).catch(e => { })
        .finally(() => {
          this.isLoading = false
        })
    },

    /**
     * Get user visited events
     */
    getVisitedEvents() {
      let self = this
      this.isLoading = true
      eventProvider.methods.getEventsProfileVisited('', 1).then(response => {
        this.eventRegistations = response.data.Results
        // if (response.status === 200) {
        //   self.morePages = response.data.MorePages
        //   if (self.morePages === 0) {
        //     self.pageState.reachedEnd = true
        //   }
        //   self.pageState.events = self.pageState.events.concat(response.data.Results)
        //   self.$store.dispatch('updateEventsPageState', self.pageState)
        // }
      }).catch(e => { })
        .then(() => {
          self.isLoading = false
        })
    },

    /**
     * Reset pageState
     */
    // resetPageState() {
    //   // Reset pageState
    //   this.pageState.reachedEnd = false
    //   this.pageState.page = 1
    //   this.pageState.events = []
    //   this.pageState.recommendedEvents = []
    //   this.pageState.searchTerm = ''
    //   this.pageState.visibleItem = 'going'
    //   this.$store.dispatch('updateEventsPageState', this.pageState)
    // },

    setShowAll() {
      // Set view
      this.$router.push({ name: 'Events' })
    },


    createHeader(val) {
      let arr = val.split('_')
      return this.$options.filters.getMonthName(arr[0], 'en', true) + ' ' + arr[1]
    },

    /**
     * Process events data
     */
    processResponse(response) {
      let _events = {}

      for (let i = 0; i < response.length; i++) {
        let date = this.$options.filters.parseIsoDateStringToDate(response[i].EventDate)
        let m = date.getMonth() + 1
        let y = date.getFullYear()
        let keyName = `${m}_${y}`
        if (typeof _events[keyName] === 'undefined') {
          _events[keyName] = []
        }
        _events[keyName].push(response[i])
      }

      return _events
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/template.scss";
.goingHero {
  position: relative;
  align-items: center;
  display: flex;
  align-content: center;
  .image {
    width: 100%;
    @media screen and (max-width: 600px) {
      padding-top: 200px;
    }
    img {
      background-image: url("~@/assets/img/events-hero-bg.jpg");
      background-position: center -70px;

      @media screen and (max-width: 600px) {
        background-position: center -30px;
      }
    }
  }
  .contentWrapper {
    position: absolute;
    left: $gap;
    right: $gap;
    .title {
      display: inline-block;
      padding: 0px 15px 8px;
      text-shadow: 2px 2px 5px $black;
    }
    .button {
      width: 100%;
      max-width: 300px;
    }
  }
}
</style>
