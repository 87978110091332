import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    async getActiveCountries() {
      const response = await axios
        .get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/country/active`, {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY
          }
        })
      store.commit('setCountries', response.data)
      return response
    }
  }
}
