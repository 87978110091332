<template>
  <div>
    <div class="">
      <div class="columns-topSection columns is-mobile">
        <div class="column-control column">
          <h2 class="title is-3">Events I organize</h2>
          <p>Below you can find events in the future and past which you organized.</p>
        </div>
        <div class="column is-half">
          <div class="box-publish-event box is-shadowless has-background-primary-light content">
            <h3 class="title is-3">Publish an event</h3>
            <p>Organizing an event? Use the button below to make your event visible on the calendar.</p>
            <a
              @click="$router.push({name: 'NewEvents'}).catch(() => { })"
              class="button is-primary is-medium"
            >Create an event</a>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <!-- <div class="mb-5 navbar-custom-menu">
      <div class="navbar-item">
        <a
          class="navbar-link is-arrowless is-unselectable is-size-7-mobile"
          :class="{ 'is-active' : isUpComingEvents }"
          @click="showUpcoming()"
        >Future events</a>
      </div>
      <div class="navbar-item">
        <a
          class="navbar-link is-arrowless is-unselectable is-size-7-mobile"
          :class="{ 'is-active' : !isUpComingEvents }"
          @click="showUpcoming(false)"
        >Past events</a>
      </div>
    </div> -->

    <Message
      v-if="!isLoading && noEventFoundMessage"
      class="is-info"
    >
      {{noEventFoundMessage}}
    </Message>

    <EventCalendarPartial :isLoading="isLoading" />

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isLoading"
        key="loader"
        class="has-text-centered"
      >
        <img src="@/assets/img/s2m-loader-big.gif" />
      </div>
      <a
        key="loadButton"
        v-if="!isLoading && morePages"
        class="button is-primary"
        @click="loadMore()"
      >
        Load more events
      </a>
    </transition>
  </div>
</template>

<script>
import eventProvider from '@/providers/event'
import Message from '../UI/Message.vue'
const EventCalendarPartial = () => import('@/components/partial/EventCalendar')

export default {
  components: {
    EventCalendarPartial,
    Message
  },

  data() {
    return {
      isLoading: true,
      isUpComingEvents: true,
      pageState: this.$store.state.eventsPageState
    }
  },

  computed: {
    noEventFoundMessage() {
      if (!this.pageState.events.length && this.isUpComingEvents) {
        return 'No upcoming events were found which organize by you.'
      }
      if (!this.pageState.events.length && !this.isUpComingEvents) {
        return 'No past events have been found which organized by you.'
      }
      return ''
    },
  },

  created() {
    let self = this
    this.resetPageState()
    this.getOrganizedEvents()
  },

  methods: {
    /**
     * Load more events
     */
    loadMore() {
      this.pageState.page++
      if (this.showComing) {
        this.getOrganizedEvents()
      } else {
      }
    },

    showUpcoming(showComing = true) {
      if (!this.isLoading) {
        this.resetPageState()
        this.isUpComingEvents = showComing

        this.getOrganizedEvents()
      }
    },

    getOrganizedEvents() {
      let self = this

      this.isLoading = true
      let itemsPerPage = this.isUpComingEvents ? this.pageState.itemsPerPage : 50

      eventProvider.methods.getEventsOrganizedByProfile(this.pageState.searchTerm, this.pageState.page, itemsPerPage, !this.isUpComingEvents).then(response => {
        if (response.status === 200) {
          self.morePages = response.data.MorePages
          if (self.morePages === 0) {
            self.pageState.reachedEnd = true
          }
          if (self.isUpComingEvents) {
            self.pageState.events = self.pageState.events.concat(response.data.Results)
            self.$store.dispatch('updateEventsPageState', self.pageState)
          } else {
            self.filterResponse(response.data.Results)
          }
        }
      })
        .catch(e => { })
        .finally(() => {
          self.isLoading = false
        })
    },

    filterResponse(data) {
      let events = []
      for (let i = 0, l = data.length; i < l; i++) {
        let dayDiff = this.$options.filters.daysBetweenCurrentDate(new Date(data[i].StartDate), new Date())
        if (dayDiff < 0) {
          events.unshift(data[i])
        }
      }
      if (events.length > 0) {
        // let reserved = events.reserved()
        this.pageState.events = this.pageState.events.concat(events)
        this.$store.dispatch('updateEventsPageState', this.pageState)
      }
    },

    resetPageState() {
      // Reset pageState
      this.pageState.reachedEnd = false
      this.pageState.page = 1
      this.pageState.events = []
      this.pageState.recommendedEvents = []
      this.pageState.searchTerm = ''
      this.pageState.visibleItem = 'organized'
      this.$store.dispatch('updateEventsPageState', this.pageState)
    }
  },

  beforeDestroy() {
    this.resetPageState()
    window.removeEventListener('scroll', null)
  }
}
</script>

<style lang="scss" scoped>
// .columns-topSection {
//   .column-control {
//     .content {
//       display: flex;
//       height: 100%;
//       flex-direction: column;
//       div.top {
//         flex-grow: 1;
//       }
//     }
//   }
// }
// @import "../../assets/styles/template.scss";
// .create-event-item {
//   cursor: pointer;
//   @include box-shadow();
//   @include rounded($gap);
//   height: 378px;
//   overflow: hidden;
//   position: relative;
//   width: 100%;
//   border: 5px solid $red-light;

//   .button-add {
//     margin-top: 20%;
//     display: inline-flex;
//   }
// }
</style>
