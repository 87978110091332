<template>
  <div
    v-if="isInitiated"
    class="edit-event-wrapper"
  >
    <div class="columns">
      <div class="column">
        <event-header
          :title="event.Name"
          :image="eventImage"
          :hideDateTime="currentStep === 1 && eventId === 0"
          :startDate="event.StartDate"
          :endDate="event.EndDate"
          :startMinutes="event.StartMinutes"
          :endMinutes="event.EndMinutes"
        />
      </div>
      <div class="column is-one-third">
        <div class="box box-event-detail is-flex has-background-grey-lighter is-shadowless">
          <div class="">
            <h3 class="title is-3">Image</h3>
            <div class="icon-text is-flex-wrap-nowrap infoLine mb-6">
              <span class="icon has-text-info">
                <i class="fas fa-info-circle"></i>
              </span>
              <span>Add a fitting image with a minimal resolution of 1280 x 720 pixels and make the page
                look good.<br /><strong class="is-uppercase">Note:</strong> Do
                not upload any content that may violate copyright law.</span>
            </div>
            <div
              v-if="visibleItem === 'what'"
              class="buttons is-centered"
            >
              <div class="button-upload file has-text-weight-bold">
                <label class="file-label">
                  <input
                    class="file-input"
                    type="file"
                    @change="selectImage"
                    rel="fileInput"
                    accept="image/*"
                  />
                  <span class="file-cta has-background-primary-dark">
                    <span class="file-icon has-text-white">
                      <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label has-text-white">Upload image</span>
                  </span>
                </label>
              </div>
              <button
                v-if="newEventImage !== '' && event.Image !== ''"
                @click="restoreToSavedImage"
                class="button is-primary has-margin-left"
              >
                <span class="icon">
                  <i class="fas fa-redo"></i>
                </span>
                <span>Restore to saved image</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <hr />
      <div
        v-if="event.Id !== 0"
        class="navbar-custom-menu"
      >
        <div class="navbar-item">
          <a
            @click="setVisbleItem('what')"
            class="icon-text navbar-link is-arrowless"
            :class="{ 'is-active': visibleItem === 'what' }"
          >
            <span class="icon is-small">
              <i
                class="far fa-file-alt"
                aria-hidden="true"
              ></i>
            </span>
            <span>What</span>
          </a>
        </div>
        <div class="navbar-item">
          <a
            @click="setVisbleItem('when')"
            class="icon-text navbar-link is-arrowless"
            :class="{ 'is-active': visibleItem === 'when' }"
          >
            <span class="icon is-small">
              <i
                class="far fa-calendar-alt"
                aria-hidden="true"
              ></i>
            </span>
            <span>When</span>
          </a>
        </div>
        <div class="navbar-item">
          <a
            @click="setVisbleItem('where')"
            class="icon-text navbar-link is-arrowless"
            :class="{ 'is-active': visibleItem === 'where' }"
          >
            <span class="icon is-small">
              <i
                class="fas fa-map-marker-alt"
                aria-hidden="true"
              ></i>
            </span>
            <span>Where & save</span>
          </a>
        </div>
        <div class="navbar-item">
          <a
            @click="setVisbleItem('invite')"
            class="icon-text navbar-link is-arrowless"
            :class="{ 'is-active': visibleItem === 'invite' }"
          >
            <span class="icon is-small">
              <i class="fas fa-share-alt"></i>
            </span>
            <span>share & inspire</span>
          </a>
        </div>
        <div class="navbar-item">
          <a
            @click="setVisbleItem('organizers')"
            class="icon-text navbar-link is-arrowless"
            :class="{ 'is-active': visibleItem === 'organizers' }"
          >
            <span class="icon is-small">
              <i class="fas fa-users"></i>
            </span>
            <span>Organizers</span>
          </a>
        </div>
        <div class="navbar-item">
          <a
            @click="setVisbleItem('backToEvent')"
            class="icon-text navbar-link is-arrowless"
          >
            <span class="icon is-small">
              <i class="fas fa-chevron-left"></i>
            </span>
            <span>Back to event</span>
          </a>
        </div>
        <div
          class="navbar-item"
          :class="{ 'is-active': visibleItem === 'delete' }"
        >
          <a
            @click="setVisbleItem('delete')"
            class="icon-text navbar-link is-arrowless has-text-danger"
            :class="{ 'is-active': visibleItem === 'delete' }"
          >
            <span class="icon is-small">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="has-text-weight-bold">Delete event</span>
          </a>
        </div>
      </div>
      <div
        v-else
        class="step-wrapper"
      >
        <ul class="steps is-large">
          <li
            class="step-item is-success"
            :class="{
              'is-active': currentStep === 1,
              'is-completed is-success': currentStep > 1
            }"
          >
            <div class="step-marker">
              <span class="icon is-medium">
                <i
                  class="far fa-file-alt"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            <div class="step-details">
              <p class="has-text-weight-bold">What</p>
            </div>
          </li>
          <li
            class="step-item"
            :class="{
              'is-active is-success': currentStep === 2,
              'is-completed is-success is-success': currentStep > 2
            }"
          >
            <div class="step-marker">
              <span class="icon is-medium">
                <i
                  class="far fa-calendar-alt"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            <div class="step-details">
              <p class="has-text-weight-bold">When</p>
            </div>
          </li>
          <li
            class="step-item"
            :class="{
              'is-active': currentStep === 3,
              'is-completed is-success': currentStep > 3
            }"
          >
            <div class="step-marker">
              <span class="icon is-medium">
                <i
                  class="fas fa-map-marker-alt"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            <div class="step-details">
              <p class="has-text-weight-bold">Where</p>
            </div>
          </li>
          <li
            class="step-item"
            :class="{
              'is-active': currentStep === 4,
              'is-completed is-success': currentStep === 4
            }"
          >
            <div class="step-marker">
              <span class="icon is-medium">
                <font-awesome-icon
                  aria-hidden="true"
                  :icon="['fas', 'flag']"
                />
              </span>
            </div>
            <div class="step-details">
              <p class="has-text-weight-bold">Done</p>
            </div>
          </li>
        </ul>
      </div>

      <hr />

      <!-- <hr class="separator" /> -->

      <transition
        name="slide-fade"
        mode="out-in"
      >
        <div
          v-if="visibleItem === 'what'"
          key="what"
          class="what-wrapper columns is-multiline"
        >
          <div class="column">
            <div class="field has-margin-bottom-x2">
              <label class="label">Event name</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="event.Name"
                  :class="{ ' is-success': event.Name.length > 5 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.Name.length <= 5,
                    'has-text-success': event.Name.length > 5
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>
            <div class="field has-margin-bottom-x2">
              <label class="label">Description</label>
              <div class="control has-icons-right">
                <textarea
                  class="textarea has-padding-right"
                  maxlength="4000"
                  v-model="event.Description"
                  :class="{ ' is-success': event.Description.length > 20 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.Description.length <= 20,
                    'has-text-success': event.Description.length > 20
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>

            <div class="tags-wrapper">
              <h3 class="title is-3">Summarize your event</h3>
              <div class="field has-margin-bottom-x2">
                <label class="label">Add tags that summarize your event</label>
                <p class="icon-text is-flex-wrap-nowrap mb-4 infoLine">
                  <span class="icon has-text-info">
                    <i class="fas fa-info-circle"></i>
                  </span>
                  <span>Make sure the tags cover the intent and content of your
                    event so our matching software can do its magic for
                    you!</span>
                </p>
                <div class="columns">
                  <div class="column is-half">
                    <div class="field has-addons">
                      <div class="control control-input">
                        <input
                          type="text"
                          class="input"
                          v-model="tagInput"
                          v-on:keyup.enter="addTag"
                          v-on:keyup.exact="getTagsAutocomplete"
                          placeholder="Add tag"
                        />
                      </div>
                      <div class="control">
                        <button
                          class="button is-primary"
                          @click="addTag"
                        >
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                    <transition
                      name="fade"
                      mode="out-in"
                    >
                      <div
                        key="loader"
                        v-if="isLoadingTagsData"
                        class="padding has-text-centered"
                      >
                        <img
                          src="@/assets/img/s2m-loader-small.gif"
                          alt="loader"
                        />
                      </div>
                      <div
                        v-else-if="
                          !isLoadingTagsData && tagsAutocomplete.length !== 0
                        "
                        class="tags tags-suggestions-wrapper"
                        key="list"
                      >
                        <span
                          v-for="(tag, index) in tagsAutocomplete"
                          :key="'ta_'+index"
                          @click="addTagBySuggestion(tag.Text)"
                          class="tag is-outlined is-white is-medium"
                        >
                          <span
                            class="text"
                            v-text="tag.Text"
                          ></span>
                          <a class="add has-background-primary"></a>
                        </span>
                      </div>
                      <div
                        v-else-if="!isLoadingTagsData && tagSuggestions.length !== 0"
                        class="tags tags-suggestions-wrapper"
                        key="list"
                      >
                        <span
                          v-for="(tag, index) in tagSuggestions"
                          :key="'ts_'+index"
                          @click="addTagBySuggestion(tag.RecTag)"
                          class="tag is-outlined is-white is-medium"
                        >
                          <span
                            class="text"
                            v-text="tag.RecTag"
                          ></span>
                          <a class="add has-background-primary"></a>
                        </span>
                      </div>
                    </transition>
                  </div>
                  <div class="column is-half">
                    <div
                      v-if="tagsChosen.length > 0"
                      class="tags"
                    >
                      <span
                        v-for="(tag, index) in tagsChosen"
                        :key="index"
                        class="tag is-outlined is-white is-medium"
                        :title="tag"
                      >
                        <span class="text">{{ tag }}</span>
                        <a
                          @click="removeTag(index)"
                          class="delete has-background-danger"
                        ></a>
                      </span>
                    </div>
                    <div v-else>No tags added...</div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="programItems-wrapper has-margin-bottom-x2">
              <h3 class="title is-3">Program <span class="has-text-weight-normal is-size-6">(optional)</span></h3>
              <p class="icon-text is-flex-wrap-nowrap mb-4 infoLine">
                <span class="icon has-text-info">
                  <i class="fas fa-info-circle"></i>
                </span>
                <span>When do the doors open, at what time and who are the speakers
                  or activities? Share what the attendees can expect.</span>
              </p>
              <transition
                name="fade"
                mode="out-in"
              >
                <button
                  v-if="!programFormIsVisible"
                  key="add-prg-button"
                  @click="programFormIsVisible = !programFormIsVisible"
                  class="button is-primary"
                >
                  <span>Create program</span>
                </button>
                <div
                  key="programmWrapper"
                  v-if="programFormIsVisible"
                  class="has-margin-bottom"
                >
                  <div
                    class="field"
                    v-for="(item, index) in sortedProgramItems"
                    :key="index"
                  >
                    <div class="field has-addons">
                      <div class="control">
                        <div class="select">
                          <select v-model="item.StartMinutes">
                            <option
                              v-for="(option, index) in timeMinutesOptions"
                              :key="index"
                              :value="option"
                            >{{ option | minutesToTime }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="control control-description">
                        <input
                          class="input"
                          maxlength="200"
                          v-model.lazy="item.Description"
                        />
                      </div>
                      <a
                        class="has-text-danger"
                        @click="deleteProgramItem(index)"
                      >
                        <span class="icon is-medium">
                          <i class="icon fas fa-lg fa-times-circle"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                  <hr class="hr-addLine has-background-grey-light ml-0" />
                  <div class="field has-addons">
                    <div class="control">
                      <div class="select">
                        <select v-model="programFormStartMinutes">
                          <option
                            v-for="(option, index) in timeMinutesOptions"
                            :key="index"
                            :value="option"
                          >{{ option | minutesToTime }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="control control-description">
                      <input
                        class="input"
                        maxlength="200"
                        v-on:keyup.13="addProgramItem"
                        v-model="programFormDescription"
                      />
                    </div>
                    <div class="control">
                      <button
                        class="button is-primary"
                        @click="addProgramItem"
                      >
                        <span class="icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span>Add</span>
                      </button>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <hr />

            <div class="url-field field has-margin-bottom-x2">
              <h3 class="title is-3">URL <span class="has-text-weight-normal is-size-6">(optional)</span></h3>
              <p class="icon-text is-flex-wrap-nowrap mb-4 infoLine">
                <span class="icon has-text-info">
                  <i class="fas fa-info-circle"></i>
                </span>
                <span>Add the link for ticket sales, registration or other
                  information that is elsewhere.</span>
              </p>
              <p>
                <ui-checkbox
                  class="has-margin-bottom"
                  v-model="event.IsTicketLink"
                  iconAdditionClass="has-text-white"
                >
                  Is link to ticket sell
                </ui-checkbox>
              </p>
              <div class="control-url control has-icons-right">
                <div class="select">
                  <select
                    @change="validateUrl(true)"
                    v-model="urlProtocolIndex"
                  >
                    <option
                      v-for="(type, index) in urlProtocols"
                      :value="index"
                      :key="index"
                    >{{ type }}</option>
                  </select>
                </div>

                <input
                  class="input"
                  type="text"
                  v-model="url"
                  @input="validateUrl(false)"
                  :class="{ ' is-success': event.Url.length > 5 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.Url.length <= 5,
                    'has-text-primary': event.Url.length > 5
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="visibleItem === 'when'"
          key="when"
          class="columns is-multiline"
        >
          <div class="column">
            <h3 class="title is-3">Event date(s)</h3>
            <div class="field">
              <p class="icon-text is-flex-wrap-nowrap mb-4 infoLine">
                <span class="icon has-text-info">
                  <i class="fas fa-info-circle"></i>
                </span>
                <span>Select the date(s) of your event.</span>
              </p>

              <div class="is-wrapper mb-6">
                <div class="field field-dates">
                  <v-date-picker
                    mode="single"
                    v-model="event.StartDate"
                    :is-inline="true"
                    :is-required="true"
                    :min-date="minDate"
                    :model-config="modelConfig"
                  />
                </div>
              </div>
            </div>

            <div class="event-time-settings-wrapper">
              <h3 class="title is-3">Time settings</h3>
              <ui-checkbox
                class="allday-checkbox is-pulled-left has-margin-right"
                v-model="isAllDay"
                label=""
                iconAdditionClass="has-text-white"
              >
                Is all day
              </ui-checkbox>
              <transition
                name="slide-fade"
                mode="out-in"
              >
                <div
                  v-if="!isAllDay"
                  class="is-pulled-left"
                >
                  <div class="select">
                    <select v-model="event.StartMinutes">
                      <option
                        v-for="(option, index) in timeMinutesOptions"
                        :key="index"
                        :value="option"
                      >{{ option | minutesToTime }}</option>
                    </select>
                  </div>
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                  <div class="select">
                    <select v-model="event.EndMinutes">
                      <option
                        v-for="(option, index) in timeMinutesOptions"
                        :key="index"
                        :value="option"
                      >{{ option | minutesToTime }}</option>
                    </select>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div
          v-if="visibleItem === 'where'"
          key="where"
          class="columns is-multiline"
        >
          <div class="column">
            <div class="field">
              <label class="label">Location Name</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-on:keyup.exact="getLocationAutocompleteData"
                  v-model="event.LocationName"
                  v-on:keyup.13="hideLocationsSuggestions"
                  :class="{ ' is-success': event.LocationName.length > 5 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.LocationName.length <= 5,
                    'has-text-success': event.LocationName.length > 5
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
              <transition name="fade">
                <div
                  class="autocomplete-wrapper has-background-white padding has-shadow"
                  v-if="locationsSuggestions.length > 0"
                  @mouseleave="hideLocationsSuggestions"
                >
                  <div class="wrapper-content">
                    <a
                      class="navbar-item"
                      v-for="(item, index) in locationsSuggestions"
                      :key="index"
                      @click="setLocation(item)"
                    >{{
                        item.Id === 0
                          ? 'All locations in ' + item.Result
                          : item.Result
                      }}</a>
                  </div>
                </div>
              </transition>
            </div>

            <div class="field">
              <label class="label">Address</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="event.Address"
                  :class="{ ' is-success': event.Address.length > 5 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.Address.length <= 5,
                    'has-text-success': event.Address.length > 5
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label">Postal code</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="event.Postalcode"
                  :class="{ ' is-success': event.Postalcode.length > 5 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.Postalcode.length <= 5,
                    'has-text-success': event.Postalcode.length > 5
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label">City</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="event.City"
                  :class="{ ' is-success': event.City.length > 5 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.City.length <= 5,
                    'has-text-success': event.City.length > 5
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>

            <div class="field">
              <label class="label">Country</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="event.Country"
                  :class="{ ' is-success': event.Country.length > 5 }"
                />
                <span
                  class="icon is-small is-right"
                  :class="{
                    'has-text-grey-light': event.Country.length <= 5,
                    'has-text-success': event.Country.length > 5
                  }"
                >
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="visibleItem === 'invite'"
          key="invite"
          class="columns-done-wrapper columns is-multiline"
        >
          <div class="column is-full has-text-centered">
            <div
              v-if="eventId === 0"
              class="title is-2 has-text-blue-dark has-text-centered"
            >
              Hooray, your event is added to our calendar and available for the
              world!
            </div>
            <div
              v-else-if="eventId !== 0 && changesSaved"
              class="title is-2 has-text-blue-dark has-text-centered"
            >
              Your changes have been saved
            </div>
            <div class="title is-3 has-text-centered">{{ event.Name }}</div>
            <hr class="separator" />
            <div
              v-if="changesSaved"
              class="has-text-centered"
            >
              <figure
                class="image is-128x128"
                style="margin: 0 auto;"
              >
                <img
                  src="@/assets/img/done.svg"
                  alt="done"
                  title="Hooray! You successfully created a new event"
                />
              </figure>
            </div>
            <hr class="separator" />
            <div class="field-copy-wrapper">
              <label class="has-text-weight-bold">Share to raise the attendance.</label>
              <div class="field-copy field has-addons">
                <div class="control control-input">
                  <input
                    type="text"
                    id="shareEventUrl"
                    :value="shareEventUrl"
                    class="input is-medium"
                  />
                </div>
                <div class="control">
                  <button
                    @click="copyShareEventUrl"
                    class="button is-medium is-primary-dark"
                  >
                    <span class="icon">
                      <i class="fas fa-copy"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <hr class="separator" />
            <button
              v-if="currentStep === 4 && !openChanges"
              @click="setVisbleItem('backToEvent')"
              :disabled="isSaving"
              class="button is-medium is-primary-dark"
            >
              Go to event
            </button>
          </div>
        </div>

        <div
          v-if="visibleItem === 'organizers'"
          key="organizers"
          class=""
        >
          <event-organizers :eventId="eventId"></event-organizers>
        </div>

        <div
          v-if="visibleItem === 'delete'"
          key="delete"
          class="has-text-centered"
        >
          <h1 class="title is-3">
            Are you sure you want to delete this event?
          </h1>
          <div class="content">
            <!-- <p class="">Note! The participants will be informed</p> -->
          </div>
          <hr class="separation" />
          <ui-checkbox
            v-model="deleteEventCheckboxIsChecked"
            iconAdditionClass="has-text-white"
            inputCheckedClass="is-danger"
          >
            I'm sure I want to delete this event.
          </ui-checkbox>
          <hr class="separator" />
          <div v-if="deleteEventCheckboxIsChecked">
            <hr />
            <a
              @click="doDeleteEvent"
              class="button is-danger"
            >Delete event</a>
          </div>
          <hr class="separator" />
        </div>
      </transition>
      <div
        v-if="visibleItem !== 'delete' && currentStep"
        class="columns"
      >
        <hr class="separator" />
        <div class="column has-text-right">
          <div>
            <div
              v-if="showNotifyAttendees && !isSaving"
              class="notification is-warning has-text-left"
            >
              <div class="subtitle">
                The location and / or the date has changed, do you want to
                notify all the people attending your event?
              </div>
              <div>
                <ui-checkbox
                  class="has-margin-bottom"
                  v-model="notifyAttendees"
                  iconAdditionClass="has-text-white"
                >
                  Send mail to visitors of the event.
                </ui-checkbox>
              </div>
            </div>

            <div class="eventStepButtons buttons is-right">
              <button
                class="button is-medium mr-4 is-ghost"
                v-if="currentStep > 1 && currentStep < 4"
                @click="gotoPreviousStep"
              >
                Previous
              </button>
              <button
                class="button is-medium"
                :disabled="!wizardStep1Compleet || isSaving"
                :class="{
                'is-primary':
                  currentStep === 1 ||
                  currentStep === 2 ||
                  currentStep === 3,
                'is-loading': isSaving,
                'is-disabled': !wizardStep1Compleet
              }"
                v-if="currentStep < 4"
                @click="gotoNextStep"
              >
                <span v-if="currentStep < 3">Next</span>
                <span v-else>{{ eventId === 0 ? 'Save' : 'Save changes' }}</span>
              </button>

              <button
                v-if="currentStep === 4 && openChanges"
                @click="setVisbleItem('backToEvent')"
                :disabled="isSaving"
                class="button is-ghost is-medium mr-4"
              >
                cancel
              </button>

              <button
                v-if="currentStep === 4 && openChanges"
                @click="saveEvent()"
                :class="{ 'is-loading': isSaving }"
                :disabled="isSaving"
                class="button is-medium is-primary"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/eventBus/event-bus'
import { mapGetters } from 'vuex'
import eventProvider from '../../providers/event'
import autocompleteProvider from '../../providers/autocomplete'
import country from '@/providers/country'
import location from '@/providers/location'
import reservation from '@/providers/reservation'
import tag from '@/providers/tag'
import hash from 'object-hash'

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})


const EventOrganizers = () => import('@/components/events/EventOrganizers')

export default {
  components: {
    'event-organizers': EventOrganizers
  },

  props: {
    eventId: {
      default: 0,
      type: Number
    },
    groupId: {
      default: 0,
      type: Number
    },
    locationId: {
      default: 0,
      type: Number
    },
    reservationId: {
      default: 0,
      type: Number
    }
  },

  data() {
    return {
      changesSaved: false,
      countries: [],
      currentStep: 1,
      deleteEventCheckboxIsChecked: false,
      event: {
        Id: 0,
        LocationId: 0,
        LocationName: '',
        Address: '',
        Postalcode: '',
        City: '',
        State: '',
        Country: '',
        Latitude: 0,
        Longitude: 0,
        ReservationId: this.reservationId,
        GroupId: this.groupId,
        SetId: 0,
        Name: '',
        Image: '',
        Description: '',
        Url: '',
        StartDate: this.$options.filters.dateObjectIsoDateString(new Date()),
        EndDate: this.$options.filters.dateObjectIsoDateString(new Date()),
        StartMinutes: 540,
        EndMinutes: 1020,
        ProgramItems: [],
        Organizers: [],
        IsPublic: 1,
        IsActive: 1,
        IsTicketLink: false,
        Tags: ''
      },
      originalEvent: {},
      newEventImage: '',
      notifyAttendees: false,
      hasEventRights: false,
      isAllDay: false,
      isInitiated: false,
      isLoading: false,
      isNewImage: false,
      isSaving: false,
      programFormIsVisible: false,
      programFormStartMinutes: 540,
      programFormDescription: '',
      selectedLocation: null,
      tagsChosen: [],
      tagInput: '',
      tagsAutocomplete: [],
      tagSuggestions: [],
      isLoadingTagsData: false,
      locationsSuggestions: [],
      locationsTimeoutControl: null,
      suggestedLocationChosen: false,
      timeMinutesOptions: this.buildTimeMinutesOptions(0, 1440, 5),
      visibleItem: 'what',
      saveActionNeeded: false,
      urlProtocols: ['http://', 'https://'],
      urlProtocolIndex: 0,
      url: '',
      wizardStep3Compleet: true,
      minDate: this.$options.filters.dateObjectIsoDateString(
        new Date(new Date().setDate(new Date().getDate() - 1))
      ),
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      }
    }
  },

  computed: {
    ...mapGetters({
      profile: 'getProfile',
    }),

    eventImage() {
      let img = '@/assets/img/placeholder_no_image.png'
      if (this.event.Image !== '' && this.newEventImage === '') {
        img = this.$options.filters.getEventImageSrc(this.event.Image, 0)
      } else if (this.newEventImage !== '') {
        img = this.newEventImage
      }
      return img
    },

    openChanges() {
      return hash(JSON.parse(JSON.stringify(this.event))) !== hash(this.originalEvent)
    },

    shareEventUrl() {
      let self = this
      let url = ''

      if (self.event) {
        url = window.location.origin + '/events/' + self.event.Id
      }

      return url
    },

    showNotifyAttendees() {
      let self = this
      let show = false
      let e = self.event
      let or = self.originalEvent

      if (e.Id > 0) {
        if (
          e.LocationName !== or.LocationName ||
          e.Address !== or.Address ||
          e.City !== or.City ||
          e.StartDate !== or.StartDate ||
          e.EndDate !== or.EndDate
        ) {
          show = true
        }
      }

      return show
    },

    programMinMinutes() {
      let minMinutes = 1440

      if (this.event.ProgramItems.length > 0) {
        for (let i = 0; i < this.event.ProgramItems.length; i++) {
          let programItem = this.event.ProgramItems[i]
          if (programItem.StartMinutes < minMinutes) {
            minMinutes = programItem.StartMinutes
          }
        }
      }

      return minMinutes
    },

    programMaxMinutes() {
      let maxMinutes = 0

      if (this.event.ProgramItems.length > 0) {
        for (let i = 0; i < this.event.ProgramItems.length; i++) {
          let programItem = this.event.ProgramItems[i]
          if (programItem.StartMinutes > maxMinutes) {
            maxMinutes = programItem.StartMinutes
          }
        }
      }

      return maxMinutes
    },

    sortedProgramItems() {
      return this.event.ProgramItems.slice().sort((a, b) => {
        // Sort by votes
        // If the first item has a higher number, move it down
        // If the first item has a lower number, move it up
        if (a.StartMinutes > b.StartMinutes) return 1
        if (a.StartMinutes < b.StartMinutes) return -1

        // If the votes number is the same between both items, sort alphabetically
        // If the first item comes first in the alphabet, move it up
        // Otherwise move it down
        if (a.Description.toLowerCase() > b.Description.toLowerCase()) return 1
        if (a.Description.toLowerCase() < b.Description.toLowerCase()) return -1
      })
    },

    wizardStep1Compleet() {
      return (
        this.event.Name.length > 3 &&
        this.event.Description !== '' &&
        this.event.Tags !== '' &&
        (this.newEventImage !== '' || this.event.Image !== '')
      )
    }
  },

  watch: {
    isAllDay() {
      if (this.isAllDay) {
        this.event.StartMinutes = 0
        this.event.EndMinutes = 1440
      } else if (
        this.event.StartMinutes === 0 &&
        this.event.EndMinutes === 1440
      ) {
        this.event.StartMinutes = 540
        this.event.EndMinutes = 1020
      }
    },

    'event.StartDate': {
      immediate: true,
      handler: function(val) {
        this.event.EndDate = val
      }
    },
  },

  created() {
    let self = this

    this.$emit('setShowLoading')
    if (this.eventId > 0) {
      eventProvider.methods.getEventState(this.eventId)
        .then(response => {
          if (response.data) {
            self.hasEventRights = response.data.CanEdit
            self.getEventData()
          } else {
            this.$router.push({
              name: 'EventDetail',
              params: { eventId: self.eventId }
            })
            this.$emit('setShowEventDetail')
          }
        })
        .catch(e => {
          this.$router.push({
            name: 'EventDetail',
            params: { eventId: this.eventId }
          })
          this.$emit('setShowEventDetail')
        })
        .then(() => { })
    } else {
      // New event
      this.isInitiated = true
      this.event.Organizers.push({
        ProfileId: this.profile.Id,
        Name: this.profile.FullName,
        Image: this.profile.Image,
        Email: this.profile.Email,
        Phone: this.profile.Phone,
        ReceiveCheckinMail: true,
        IsPrimary: true
      })
    }

    if (this.locationId > 0) {
      this.getLocationData(this.locationId)
    }

    if (this.reservationId > 0) {
      this.getReservationData(this.reservationId)
    }
  },

  methods: {
    addTag() {
      let self = this
      this.tagSuggestions = []
      this.tagsAutocomplete = []

      let arr = this.tagInput
        .trim()
        .split(/([,/|;]+)/g)
        .filter(word => /\w/.test(word))
      arr.map((word, i) => {
        word = word.trim()

        if (word !== '' && !this.tagAlreadyAdded(word)) {
          this.tagsChosen.push(word)
          this.event.Tags = String(this.tagsChosen)
        }
      })
      this.getTagSuggestionsAutocomplete(this.tagInput)
      this.tagInput = ''
    },

    addProgramItem() {
      this.event.ProgramItems.push({
        Id: 0,
        EventId: this.event.Id,
        StartMinutes: this.programFormStartMinutes,
        Description: this.programFormDescription
      })
      this.programFormStartMinutes = 540
      this.programFormDescription = ''

      this.event.StartMinutes = this.programMinMinutes
      this.event.EndMinutes =
        this.programMaxMinutes + 60 > 1440 ? 1440 : this.programMaxMinutes + 60
    },

    addTagBySuggestion(input) {
      if (!this.tagAlreadyAdded(input.trim())) {
        this.tagsChosen.push(input.trim())
        this.event.Tags = String(this.tagsChosen)
      }

      this.getTagSuggestionsAutocomplete(input)
      this.tagInput = ''
    },

    buildTimeMinutesOptions(open, close, minutesSteps) {
      let output = []
      let oneMinute = 1

      for (let i = open; i < close; i = i + minutesSteps) {
        output.push(i)
      }
      return output
    },

    cleanupTagsCompleteResult(response) {
      for (let i in this.tagsChosen) {
        for (let x in response) {
          let tagText = (response[x].Text ? response[x].Text : response[x].RecTag).trim().toLowerCase()
          if (this.tagsChosen[i].trim().toLowerCase() === tagText) {
            response.splice(x, 1)
            break
          }
        }
      }
      return response
    },

    doDeleteEvent() {
      if (this.deleteEventCheckboxIsChecked) {
        eventProvider.methods.deleteEvent(this.eventId)
          .then(response => {
            this.$router.push({ name: 'OrganizedEvents' })
          })
          .catch(e => {
            alert('Oops, Could not delete this event')
          })
          .then(() => { })
      }
    },

    deleteProgramItem(index) {
      this.event.ProgramItems = this.sortedProgramItems
      this.event.ProgramItems.splice(index, 1)
      if (this.event.ProgramItems.length <= 0) {
        this.programFormIsVisible = false
      }
    },

    formatDates(startDateVal = '', endDateVal = '') {
      let dateFormat = `${startDateVal}`
      return dateFormat
    },

    getLocationAutocompleteData() {
      clearTimeout(this.locationsTimeoutControl)
      if (!this.suggestedLocationChosen) {
        if (this.event.LocationName.length > 2) {
          this.locationsTimeoutControl = setTimeout(() => {
            autocompleteProvider.methods
              .getAutocompleteLocationItems({ channelId: 1, searchTerm: this.event.LocationName })
              .then(response => {
                this.locationsSuggestions = response.data
              })
          }, 200)
        }
      }
      this.suggestedLocationChosen = false
    },

    getTagsAutocomplete() {
      let input = this.tagInput.trim()
      tag.methods.cancelGetTagSuggestion()
      this.tagsAutocomplete = []
      this.tagSuggestions = []

      if (input.length > 2) {
        this.isLoadingTagsData = true
        autocompleteProvider.methods.getAutocompleteTagItems({ q: input })
          .then(response => {
            let data = response.data
            if (data.length > 0) {
              data = this.cleanupTagsCompleteResult(data)
            }
            this.tagsAutocomplete = data
          })
          .catch(e => {
            this.tagSuggestions = []
            this.tagsAutocomplete = []
          })
          .then(() => {
            this.isLoadingTagsData = false
          })
      }
    },

    getTagSuggestionsAutocomplete(input) {
      input = input.trim()
      tag.methods.cancelGetTagSuggestion()
      this.tagsAutocomplete = []
      this.tagSuggestions = []

      if (input.length > 2) {
        this.isLoadingTagsData = true
        tag.methods
          .getTagSuggestion(input)
          .then(response => {
            let data = response.data.Results
            if (data.length > 0) {
              data = this.cleanupTagsCompleteResult(data)
              this.tagSuggestions = data
            }
          })
          .catch(e => {
            this.tagSuggestions = []
            this.tagsAutocomplete = []
          })
          .then(() => {
            this.isLoadingTagsData = false
          })
      }
    },

    getEventData() {
      let self = this
      this.isLoading = true

      eventProvider.methods.getEventById(self.eventId)
        .then(response => {
          if (response.status === 200) {
            this.isLoading = false

            for (let i in response.data.ProgramItems) {
              response.data.ProgramItems[i].Id = 0
            }

            this.event = response.data
            this.event.Name = this.event.Name
            this.event.Description = this.event.Description
            this.event.Tags = this.event.Tags
            this.url = this.event.Url
            this.validateUrl(false)

            this.originalEvent = JSON.parse(JSON.stringify(this.event))

            if (
              this.event.StartMinutes === 0 &&
              this.event.EndMinutes === 1440
            ) {
              this.isAllDay = true
            }
            if (this.event.Tags.trim() !== '') {
              this.tagsChosen = this.event.Tags.split(',')
            }
            if (this.event.ProgramItems.length > 0) {
              this.programFormIsVisible = true
            }
          }
        })
        .catch(e => { })
        .finally(() => {
          this.$emit('setShowLoading')
          this.isLoading = false
          this.isInitiated = true
        })
    },

    getLocationData(locationId) {
      let self = this
      location.methods.getLocationByLocationId(locationId).then(response => {
        if (response.status === 200) {
          self.event.LocationId = response.data.Id
          self.event.LocationName = response.data.Name
          self.event.Address = response.data.Address
          self.event.Postalcode = response.data.Zipcode
          self.event.City = response.data.City
          self.event.Latitude = response.data.Latitude
          self.event.Longitude = response.data.Longitude
        }
      })
    },

    getReservationData(reservationId) {
      let self = this

      reservation.methods.getReservationById(reservationId).then(response => {
        if (response.status === 200) {
          self.event.ReservationId = response.data.Id
          self.event.StartDate = response.data.StartDate
          self.event.StartMinutes = response.data.StartMinutes
          self.event.EndDate = response.data.EndDate
          self.event.EndMinutes = response.data.EndMinutes

          self.getLocationData(response.data.LocationId)
        }
      })
    },

    hideLocationsSuggestions() {
      this.locationsSuggestions = []
    },

    removeTag(index) {
      this.tagsChosen.splice(index, 1)
      this.event.Tags = String(this.tagsChosen)
    },

    copyShareEventUrl() {
      let copyUrl = document.getElementById('shareEventUrl')
      copyUrl.select()
      document.execCommand('copy')
    },

    async saveEvent() {
      this.isSaving = true
      try {
        if (this.newEventImage !== '') {
          let uploadResponse = await eventProvider.methods.uplouadEventImage(this.event.Id, this.newEventImage)
          if (uploadResponse.status === 200) {
            this.event.Image = uploadResponse.data
          }
        }

        // Create or update event
        await this.postEventData()
      }
      catch (e) {
        console.log(e)
      }
      finally {
        this.isSaving = false
      }
    },

    postEventData() {
      let self = this
      self.event.ProgramItems = self.sortedProgramItems
      self.event.NotifyAttendees = self.notifyAttendees

      let saveUpdateCall = this.event.Id === 0 ?
        eventProvider.methods.saveEvent
        :
        eventProvider.methods.updateEvent

      return saveUpdateCall(self.event).then(response => {
        if (response.status === 200 || response.status === 201) {
          for (let i = 0, l = response.data.ProgramItems.length; i < l; i++) {
            response.data.ProgramItems[i].Id = 0
          }
          self.notifyAttendees = false
          self.event.NotifyAttendees = self.notifyAttendees
          self.changesSaved = true
          self.event = response.data
          self.originalEvent = JSON.parse(JSON.stringify(response.data))
          EventBus.$emit('eventSaved', self.event)
        }
      })
    },

    restoreToSavedImage() {
      this.newEventImage = ''
    },

    setLocation(result) {
      this.hideLocationsSuggestions()
      this.suggestedLocationChosen = true

      this.event.LocationId = result.Id

      location.methods.getLocationByLocationId(result.Id).then(response => {
        this.selectedLocation = response
        country.methods
          .getActiveCountries()
          .then(countries => {
            let country = countries.data.filter(country => {
              if (country.Id === Number(response.data.Country)) return country
            })
            this.event.LocationName = response.data.Name
            this.event.Address = response.data.Address
            this.event.Postalcode = response.data.Zipcode
            this.event.City = response.data.City
            this.event.State = response.data.State
            this.event.Country = country[0].Name
            this.event.Latitude = response.data.Latitude
            this.event.Longitude = response.data.Longitude
          })
          .catch(e => { })
          .then(() => { })
      })
    },

    selectImage(event) {
      let uploadFile = event.target.files[0]
      this.isValidImage = true
      this.isNewImage = true

      if (!this.$options.filters.checkFileType(uploadFile.type)) {
        this.isValidImage = false
        return false
      }

      this.$options.filters.getOrientation(uploadFile, orientation => {
        let reader = new FileReader()

        reader.onload = e => {
          let image = document.createElement('img')
          let canvas = document.createElement('canvas')
          let context = canvas.getContext('2d')

          image.addEventListener(
            'load',
            r => {
              let canvas = document.createElement('canvas')
              let context = canvas.getContext('2d')

              /* crop to rect. */
              let imageWidth
              let imageHeight
              let offsetX = 0
              let offsetY = 0

              let maxWidth = 1920 // Max width for the image
              let maxHeight = 1920 // Max height for the image
              let ratio = 0 // Used for aspect ratio
              let width = image.width // Current image width
              let height = image.height // Current image height

              // Check if the current width is larger than the max
              if (width > maxWidth) {
                ratio = maxWidth / width // get ratio for scaling image
                height = height * ratio // Reset height to match scaled image
                width = width * ratio // Reset width to match scaled image
              }

              // Check if current height is larger than max
              if (height > maxHeight) {
                ratio = maxHeight / height // get ratio for scaling image
                width = width * ratio // Reset width to match scaled image
              }
              canvas.width = width
              canvas.height = height
              context.fillStyle = 'lightgray'
              context.fillRect(0, 0, width, height)
              // transform context before drawing image
              switch (orientation) {
                case 6:
                  context.rotate((90 * Math.PI) / 180)
                  context.drawImage(image, 0, 0, width, height)
                  break
                case 3:
                  context.rotate((180 * Math.PI) / 180)
                  context.drawImage(image, 0, 0, width, height)
                  break
                case 8:
                  context.rotate((-90 * Math.PI) / 180)
                  context.drawImage(image, 0, 0, width, height)
                  break
                default:
                  context.drawImage(image, 0, 0, width, height)
              }

              let data = canvas.toDataURL('image/jpeg')
              this.newEventImage = data
            },
            false
          )
          image.src = e.target.result
        }
        reader.readAsDataURL(uploadFile)
      })
    },

    setVisbleItem(item) {
      this.deleteEventCheckboxIsChecked = false

      if (item !== 'backToEvent' && item !== 'organizers' && item !== 'delete') {
        this.visibleItem = item
        this.currentStep = 0

        if (item === 'what') {
          this.currentStep = 1
          this.changesSaved = false
        } else if (item === 'when') {
          this.currentStep = 2
          this.changesSaved = false
          // this.prog
        } else if (item === 'where') {
          this.currentStep = 3
          this.changesSaved = false
        } else if (item === 'invite') {
          this.currentStep = 4
        }
      } else {
        if (this.openChanges) {
          if (
            window.confirm(
              'There are unsaved changes. Do you want to save the changes?'
            )
          ) {
            this.saveEvent()
            if (item !== 'organizers' && item !== 'delete') {
              this.$emit('setShowEventDetail')
            }
            this.visibleItem = 'invite'
            this.currentStep = 4
          } else {
            this.event = this.originalEvent

            if (item === 'backToEvent') {
              this.$router.push({
                name: 'EventDetail',
                params: { eventId: this.event.Id }
              })
              this.$emit('setShowEventDetail')
            }
            else if (item === 'organizers' || item === 'delete') {
              this.visibleItem = item
              this.currentStep = 0
            }
          }
        } else {
          // Go back to event without saving
          if (item === 'backToEvent') {
            this.$router.push({
              name: 'EventDetail',
              params: { eventId: this.event.Id }
            }).catch()
            this.$emit('setShowEventDetail')
            return
          }
          this.visibleItem = item
          this.currentStep = 0
        }
      }
    },

    tagAlreadyAdded(tag) {
      var index = this.tagsChosen.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    },

    gotoNextStep() {
      ++this.currentStep
      this.wizardProcess()
    },

    gotoPreviousStep() {
      this.currentStep--
      this.wizardProcess()
    },

    wizardProcess() {
      switch (this.currentStep) {
        case 2:
          this.visibleItem = 'when'
          break
        case 3:
          this.visibleItem = 'where'
          break
        case 4:
          this.saveEvent()
          this.visibleItem = 'invite'
          break
        default:
          this.visibleItem = 'what'
          break
      }
    },

    validateUrl(skipCheck = false) {
      let self = this

      if (!skipCheck) {
        if (
          (this.url.indexOf('http://') === -1 &&
            this.url.indexOf('https://') === -1) ||
          this.url.indexOf('https://') !== -1
        ) {
          this.urlProtocolIndex = 1
        } else {
          // Is http index
          this.urlProtocolIndex = 0
        }
      }

      this.url = this.url.replace(/^https?\:\/\//i, '')

      this.event.Url =
        this.url !== ''
          ? this.urlProtocols[this.urlProtocolIndex] + this.url
          : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.box-event-detail {
  height: 100%;
}

.infoLine {
  display: flex;
  margin-bottom: 10px;
}

.what-wrapper {
  .button-upload {
    display: inline-block;
  }

  .programItems-wrapper {
    .control-description {
      width: 100%;
    }

    .hr-addLine,
    .field {
      width: 100%;
      max-width: ($fullhd / 2);
    }
  }

  .url-field {
    .control {
      display: inline-flex;
      width: 100%;
      max-width: ($fullhd / 2);
    }
    .input {
      width: 100%;
    }
  }

  .tags-wrapper {
    .tag {
      max-width: 100%;
      .text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .control-input {
      width: 50%;
    }
  }

  .tags-suggestions-wrapper {
    .row {
      border-bottom: 1px solid $grey-light;
      position: relative;
      padding: 10px 0 10px 30px;
      a {
        position: absolute;
        left: 0;
      }
    }
  }
}

.event-time-settings-wrapper {
  .allday-checkbox,
  .icon {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.autocomplete-wrapper {
  @include box-shadow(false, 0, 3px, 5px, 0, $black, 0.4);
  background-color: white;
  padding: 5px;
  position: absolute;
  z-index: 5;
  width: 95%;
  .wrapper-content {
    max-height: 200px;
    overflow: auto;
    a {
      cursor: pointer;
      display: block;
      padding: 3px 5px;
      &:hover {
        color: white;
        background-color: $cyan;
      }
    }
  }
}

.field-copy-wrapper {
  display: inline-block;
  margin: 0 auto;
  width: 50%;
  .field-copy {
    .control:first-child {
      width: 100%;
      min-width: 300px;
    }
  }
}

.eventStepButtons {
  .button {
    min-width: 140px;
  }
}
</style>
