<template>
  <div class="eventsSection">
    <!-- NAVBAR - IS LOGGED IN -->
    <template v-if="isLoggedIn">
      <nav class="navbar-custom-menu">
        <div class="navbar-item">
          <router-link
            :to="{ name: 'Dashboard'}"
            class="navbar-link is-arrowless icon-text"
          >
            <span class="icon is-small">
              <i class="fas fa-chevron-left"></i>
            </span>
            <span>Passport</span>
          </router-link>
        </div>
        <div class="navbar-item">
          <a
            @click="setShowNew"
            class="navbar-link is-arrowless is-hidden-mobile"
            :class="{ 'is-active': showNew }"
          >Create an event</a>
        </div>
        <div class="navbar-item">
          <a
            @click="setShowAll"
            class="navbar-link is-arrowless is-hidden-mobile"
            :class="{ 'is-active': showAll }"
          >Explore events</a>
        </div>
        <div class="navbar-item">
          <a
            @click="setShowRecommended"
            class="navbar-link is-arrowless is-hidden-mobile"
            :class="{ 'is-active': showRecommended }"
          >Relevant events for you</a>
        </div>
        <div class="navbar-item">
          <a
            @click="setShowOrganized"
            class="navbar-link is-arrowless is-hidden-mobile"
            :class="{ 'is-active': showOrganized }"
          >Events I organize</a>
        </div>
        <div class="navbar-item">
          <a
            @click="setShowAttended"
            class="navbar-link is-arrowless is-hidden-mobile"
            :class="{ 'is-active': showAttended }"
          >Events I go to</a>
        </div>
      </nav>
      <hr />
    </template>

    <div class="contentWrapper">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <!-- show All -->
        <div
          key="showAll"
          v-if="showAll"
        >
          <div class="columns mb-0">
            <div class="column is-half">
              <h3 class="title is-3">Discover events</h3>
              <div class="content mb-6">
                <p>Many events are organized within the Seats2meet network. From monthly get-togethers to cutting-edge tech gatherings. </p> <br>
                <p>Are you looking for the right place for your event? Seats is the place to be. Contact your favorite location to discuss the options.</p>
              </div>
              <div class="box-publish-event box is-shadowless has-background-primary-light content">
                <h3 class="title is-3">Publish an event</h3>
                <p>Organizing an event? Use the button below to make your event visible on the calendar.</p>
                <a
                  @click="setShowNew()"
                  class="button is-primary is-medium"
                >Create an event</a>
              </div>
            </div>
            <div class="column is-half">
              <div class="hero-image-wrapper">
                <div class="hero-image"></div>
              </div>
            </div>
          </div>

          <template v-if="!isLoggedIn">
            <hr />
            <nav class="navbar-custom-menu">
              <div class="navbar-item">
                <a
                  @click="setShowAll"
                  class="navbar-link is-arrowless"
                  :class="{'is-active' : showAll}"
                >
                  <span>Explore Events</span>
                </a>
              </div>
              <div class="navbar-item">
                <a
                  @click="setShowRecommended"
                  class="navbar-link is-arrowless"
                  :class="{'is-active' : showRecommended}"
                >
                  <span>Relevant Events</span>
                </a>
              </div>
            </nav>
            <hr />
          </template>

          <h2
            class="title is-3"
            :class="{ 'mt-6': isLoggedIn }"
          >{{ pageTitle }}</h2>
          <p v-if="pageDescription">{{ pageDescription }}</p>

          <!-- No events found -->
          <div
            v-if="!isLoading && Object.keys(pageState.events).length === 0"
            class="notification is-info icon-text is-flex-wrap-nowrap"
          >
            <span class="icon notification-icon fa-lg">
              <i class="fas fa-info-circle"></i>
            </span>
            <span>I'm sorry! No upcoming events found at this moment.</span>
          </div>

          <transition-group
            name="fade"
            mode="out-in"
          >
            <div
              key="events"
              v-if="!isLoading && Object.keys(pageState.events).length !== 0"
            >
              <!-- Events -->
              <template v-for="(events, key) in pageState.events">
                <div
                  :key="'title_'+key"
                  class="mt-4 title is-4 is-uppercase"
                >{{createHeader(key)}}</div>
                <div
                  class="columns columns-events is-multiline is-mobile"
                  :key="key"
                >

                  <div
                    class="column is-half"
                    v-for="event in events"
                    :key="event.Id"
                  >
                    <EventTile :event="event" />
                  </div>
                </div>
              </template>
            </div>

            <loader
              key="loader"
              v-if="isLoading"
            />
            <div
              v-if="!isLoading && !pageState.reachedEnd"
              key="loadMoreButton"
              class="has-text-centered"
            >
              <a
                @click="loadMoreEvents"
                class="button is-primary"
              >Load more events</a>
            </div>
          </transition-group>
        </div>

        <!-- show Recommended / relevant -->
        <div
          key="showRecommended"
          v-if="showRecommended"
        >
          <h2 class="title is-3">{{ pageTitle }}</h2>
          <div class="mb-6">{{ pageDescription }}</div>

          <loader
            key="loader"
            v-if="isLoading"
          />
          <div
            key="recommendedEvents"
            v-else-if="!isLoading && Object.keys(pageState.recommendedEvents).length"
          >
            <template v-for="(events, key) in pageState.recommendedEvents">
              <div
                :key="'rTitle_'+key"
                class="mt-4 title is-4 is-uppercase"
              >{{createHeader(key)}}</div>

              <div
                class="columns columns-events is-multiline is-mobile has-background-white"
                :key="'recommended_' + key"
              >
                <div
                  class="column is-half"
                  v-for="event in events"
                  :key="'recommented_evt' + event.Id"
                >
                  <match-item
                    :match="event"
                    class="event-item"
                  ></match-item>
                </div>
              </div>
            </template>
          </div>

          <div v-else>
            <div class="mt-4 notification icon-text is-flex-wrap-nowrap is-info">
              <span class="is-flex-shrink-0 icon notification-icon fa-lg">
                <i class="fas fa-info-circle"></i>
              </span>
              <div class="is-flex-grow-1">
                <div v-if="pageState.searchTerm === ''">Oops, no events found based on your profile. How about describing what you are looking for?</div>
                <div v-if="pageState.searchTerm !== ''">Oops, no events found based on your search. How about describing it in a different way?</div>
                <div class="mt-4 searchWrapper">
                  <div class="field has-addons is-justify-content-flex-start">
                    <div class="control control-input">
                      <input
                        class="input"
                        type="text"
                        placeholder="What kind of event are you looking for?"
                        v-on:keyup.enter="doSearch"
                        v-model="pageState.searchTerm"
                      />
                    </div>
                    <div class="control">
                      <button
                        @click="doSearch"
                        class="button is-primary"
                      >
                        <span class="icon">
                          <i class="fas fa-search"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- show New -->
        <div
          key="showNew"
          v-if="showNew"
        >
          <edit-event></edit-event>
        </div>

        <!-- show Organized -->
        <div
          key="showOrganized"
          v-if="showOrganized"
        >
          <MyEvents />
        </div>

        <!-- show Attended -->
        <div
          key="showAttended"
          v-if="showAttended"
        >
          <Attending />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/eventBus/event-bus'
import event from '@/providers/event'
import match from '@/providers/match'
import EventTile from '../components/events/EventTile.vue'
import Loader from '../components/UI/Loader.vue'

const EditEvent = () => import(/* webpackChunkName: "Events" */ '@/components/events/EditEvent')
const MyEvents = () => import(/* webpackChunkName: "Events" */ '@/components/events/MyEvents')
const Attending = () => import(/* webpackChunkName: "Events" */ '@/components/events/Attending')
// const EventItem = () => import(/* webpackChunkName: "Events" */ '@/components/events/EventItem')
const MatchItem = () => import(/* webpackChunkName: "Events" */ '@/components/MatchItem')

export default {
  components: {
    Attending,
    MyEvents,
    'edit-event': EditEvent,
    // 'event-item': EventItem,
    'match-item': MatchItem,
    EventTile,
    Loader
  },

  props: {
    pageView: {
      type: String,
      default: 'all'
    }
  },

  data() {
    return {
      pageTitle: '',
      pageDescription: '',
      isNewMonth: 0,
      isLoading: false,
      isLoadingItems: false,
      pageState: this.$store.state.eventsPageState,
      showAll: false,
      showAttended: false,
      showNew: false,
      showOrganized: false,
      showRecommended: false,
      matchCancellationToken: null
    }
  },

  computed: {
    ...mapGetters({
      langPrefix: 'getCurrentLanguagePrefix',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile'
    })
  },

  watch: {
    '$route.name': function() {
      this.forceRerender()
    }
  },

  created() {
    window.scrollTo(0, 0)

    this.setVisibleItem(this.pageView)

    EventBus.$on('eventDeleted', event => {
      this.deleteEvent(event)
    })
  },

  beforeDestroy() {
    EventBus.$off('eventDeleted')
    window.removeEventListener('scroll', null)
  },

  methods: {
    createHeader(val) {
      let arr = val.split('_')
      return (
        this.$options.filters.getMonthName(arr[0], this.langPrefix, true) +
        ' ' +
        arr[1]
      )
    },

    deleteEvent(eventToDelete) {
      let events = this.pageState.events

      let eventIndex = events.findIndex(e => e.Id === eventToDelete.Id)
      events.splice(eventIndex, 1)

      this.pageState.events = events
      this.$store.dispatch('updateEventsPageState', this.pageState)
    },

    doSearch() {
      // Reset pageState
      if (this.isLoading) { return }

      this.pageState.reachedEnd = false
      this.pageState.page = 1
      this.pageState.events = []
      this.pageState.recommendedEvents = []

      if (this.showRecommended) {
        this.getRecommendedEvents()
      } else {
        this.getAllEvents()
      }
    },

    forceRerender() {
      this.$nextTick()
        .then(() => {
          if (this.$route.name === 'NewEvents') {
            this.setVisibleItem(this.pageView)
          }
        })
        .catch()
    },

    getAllEvents() {
      if (this.isLoading) { return }
      // Show loader
      this.isLoading = true
      this.isLoadingItems = true

      event.methods
        .getEventCalendar(
          this.pageState.searchTerm,
          this.pageState.page,
          this.pageState.itemsPerPage
        )
        .then(response => {
          if (response.status === 200) {
            this.processEventResponse(response)
          }
        })
        .catch(e => { })
        .then(() => {
          this.isLoading = false
          this.isLoadingItems = false
        })
    },

    getRecommendedEvents() {
      let self = this
      let events = {}
      let workingon = ''

      if (this.isLoading) { return }

      if (this.profile) {
        workingon = this.profile.Tags
      }
      if (this.pageState.searchTerm !== '') {
        workingon = this.pageState.searchTerm
      }

      if (workingon.length > 0) {
        this.isLoading = true
        match.methods
          .getMatches(
            workingon,
            this.latitude,
            this.longitude,
            this.page,
            this.itemsPerPage,
            '2'
          )
          .then(response => {
            if (response.data.MorePages === 0) {
              self.pageState.reachedEnd = true
            }

            if (self.pageState.page > 1) {
              events = self.pageState.recommendedEvents
            }
            for (let i = 0; i < response.data.Results.length; i++) {
              let date = this.$options.filters.parseIsoDateStringToDate(
                response.data.Results[i].StartTime
              )
              let m = date.getMonth() + 1
              let y = date.getFullYear()
              let keyName = `${m}_${y}`
              if (typeof events[keyName] === 'undefined') {
                events[keyName] = []
              }
              events[keyName].push(response.data.Results[i])
            }
            self.pageState.recommendedEvents = events
          })
          .catch(e => { })
          .then(() => {
            self.isLoading = false
          })
      } else {
        self.isLoading = false
      }
    },

    processEventResponse(response) {
      let self = this
      let events = {}
      let responseData = response.data

      if (responseData.MorePages === 0) {
        self.pageState.reachedEnd = true
      }

      if (self.pageState.page > 1) {
        events = self.pageState.events
      }

      for (let i = 0; i < responseData.Results.length; i++) {
        let date = this.$options.filters.parseIsoDateStringToDate(
          responseData.Results[i].StartDate
        )
        let m = date.getMonth() + 1
        let y = date.getFullYear()
        let keyName = `${m}_${y}`
        if (typeof events[keyName] === 'undefined') {
          events[keyName] = []
        }
        events[keyName].push(responseData.Results[i])
      }
      this.pageState.events = events

      this.$store.dispatch('updateEventsPageState', this.pageState)
    },

    setShowAll() {
      let self = this
      // Push GA event
      this.$googleAnalytics.pushEvent(
        'events',
        this.isLoggedIn ? 'loggedIn' : 'notLoggedIn',
        this.isLoggedIn ? 'tab explore' : 'button explore'
      )

      // Set view
      self.setVisibleItem('explore')
    },

    setShowAttended() {
      this.setVisibleItem('going')
    },

    setShowNew(trackEvent = true) {
      // Push GA event
      if (trackEvent) {
        this.$googleAnalytics.pushEvent(
          'events',
          this.isLoggedIn ? 'loggedIn' : 'notLoggedIn',
          this.isLoggedIn ? 'tab create' : 'button create'
        )
      }

      // Set view
      this.setVisibleItem('new')
    },

    setShowOrganized() {
      this.setVisibleItem('organized')
    },

    setShowRecommended() {
      // Push GA event
      this.$googleAnalytics.pushEvent(
        'events',
        this.isLoggedIn ? 'loggedIn' : 'notLoggedIn',
        this.isLoggedIn ? 'tab relevant' : 'button relevant'
      )

      // Set view
      this.setVisibleItem('relevant')
    },

    setVisibleItem(item) {
      let self = this
      window.removeEventListener('scroll', null)

      // Cancel loads
      event.methods.cancelGetEventCalendar()
      match.methods.cancelGetMatches()

      // Reset pageState
      this.pageState.reachedEnd = false
      this.pageState.page = 1
      this.pageState.events = []
      this.pageState.recommendedEvents = []
      this.pageState.searchTerm = ''

      // Set view visible
      this.showAll = item === 'explore'
      this.showAttended = item === 'going'
      this.showNew = item === 'new'
      this.showOrganized = item === 'organized'
      this.showRecommended = item === 'relevant'

      switch (item) {
        case 'explore':
          this.pageTitle = 'Explore all events'
          this.pageDescription = ''
          this.$router.push({ name: 'Events' }).catch(() => { })
          break
        case 'going':
          // this.pageTitle = 'Events I am going to'
          // this.pageDescription = 'Overview of the events you are registered for.'
          // this.$router.push({ name: 'AttendingEvents' })
          break
        case 'new':
          this.pageTitle = 'Organize an event!'
          this.pageDescription = ''
          this.$router.push({ name: 'NewEvents' }).catch(() => { })
          break
        case 'relevant':
          this.pageTitle = 'Relevant events for you!'
          this.pageDescription =
            'Use the S2M serendipity power to discover events that match you. Based on your profile we found the following relevant events for you.'
          this.$router.push({ name: 'RelevantEvents' }).catch(() => { })
          break
      }

      this.pageState.visibleItem = item

      this.$store.dispatch('updateEventsPageState', this.pageState)

      if (this.showAll) {
        this.isLoading = false
        this.isLoadingItems = false
        this.getAllEvents()
      }

      if (this.showOrganized) {
        this.$router.push({ name: 'OrganizedEvents' }).catch(() => { })
        this.isLoading = false
        this.isLoadingItems = false
        // this.getOrganizedEvents()
      }

      if (this.showAttended) {
        this.$router.push({ name: 'AttendingEvents' }).catch(() => { })
        this.isLoading = false
        this.isLoadingItems = false
        // this.getAttendingEvents()
      }

      if (this.showRecommended) {
        this.getRecommendedEvents()
      }
    },

    loadMoreEvents() {
      if (!this.isLoadingItems) {
        this.pageState.page = this.pageState.page + 1
        if (this.showAll) {
          this.getAllEvents()
        }
        if (this.showOrganized) {
          this.getOrganizedEvents()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
// .eventHero {
//   .imageWrapper {
//     position: relative;
//     align-items: center;
//     display: flex;
//     align-content: center;

//     .textWrapper {
//       left: $gap;
//       position: absolute;
//       right: $gap;
//       text-shadow: 2px 2px 5px $black;
//       z-index: 1;
//     }

//     .image {
//       width: 100%;
//       @media screen and (max-width: 600px) {
//         padding-top: 360px;
//       }
//       img {
//         background-image: url("~@/assets/img/events-hero-bg.jpg");
//         background-position: center -70px;

//         @media screen and (max-width: 600px) {
//           background-position: center 0px;
//         }
//       }
//     }
//   }
// }

.hero-image {
  background-repeat: no-repeat;
  background-size: cover;
  mask-image: url(/public/img/hero-imageMask.svg);
  mask-size: 100% 100%;
  width: 100%;
  background-image: url("/public/img/events/hero-event_v2.jpg");
  aspect-ratio: 4/3;
  // height: 600px;
}

.searchWrapper,
.searchBar {
  .field {
    justify-content: center;
    .control:first-child {
      width: 45%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

.columns-events {
  align-items: stretch;
  .column {
    @media screen and (max-width: 500px) {
      width: 100% !important;
    }
    .event-item {
      height: 100%;
    }
  }
}

.create-event-item {
  cursor: pointer;
  @include box-shadow();
  @include rounded($gap);
  height: 378px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 5px solid $red-light;

  .button-add {
    margin-top: 20%;
    display: inline-flex;
  }
}

.quickAction-wrapper {
  .image {
    height: auto;
    margin: 0 auto 5px auto;
    max-width: 128px;
    width: 100%;
  }
  .image {
    @media screen and (max-width: 700px) {
      max-width: 96px;
    }
  }
  &.loggedIn {
    .image {
      max-width: 96px;
    }
  }

  .is-active {
    opacity: 0.25;
  }
}
</style>
