<template>
  <div
    @click="gotToEvent()"
    class="eventTile box p-0 is-shadowless eventTile has-background-grey-lighter is-clickable"
  >
    <div class="col-image">
      <figure class="image is-4by3 tile-image">
        <img
          class="has-background-grey-light"
          src="/img/spacer1_1.gif"
          :style="{ backgroundImage: `url('${image}')` }"
        />
      </figure>
    </div>
    <div class="col-content p-4">
      <div class="mb-3">
        <h5 class="title is-5 mb-4">
          <div
            v-if="showTypeLabel"
            class="mb-2 is-size-7 has-text-grey"
          >EVENT</div>
          <a
            @click="gotToEvent()"
            v-html="name"
            class="has-text-black"
          >
          </a>
        </h5>
        <div
          class="is-6 has-text-primary has-text-weight-bold"
          v-html="date"
        >
        </div>
      </div>
      <div class="icon-text is-flex-wrap-nowrap has-text-right">
        <a class="link-goto icon-text is-align-items-center">
          <span class="icon is-small mr-1">
            <font-awesome-icon :icon="['fas', 'angle-right']" />
          </span>
          <span>Go to event</span>
        </a>
        <!-- <span class="icon has-text-primary-dark">
          <font-awesome-icon :icon="['fas', 'map-marker']" />
        </span>
        <span>
          {{locationAddress}}
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventTile',

  props: {
    eventRegistration: {
      type: Object,
      required: true,
    },
    showTypeLabel: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    }
  },

  computed: {
    name() {
      return this.eventRegistration.EventName;
    },

    date() {
      let strDate = this.$options.filters.dateFormat(this.eventRegistration.EventDate, 'en')
      return strDate
    },


    locationAddress() {
      let locationName = this.eventRegistration.LocationName

      if (this.eventRegistration.City) {
        var cityInSnakeCase = this.eventRegistration.City.toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.substring(1))
          .join(' ');

        locationName = locationName + ", " + cityInSnakeCase
      }

      if (this.eventRegistration.Country) {
        locationName = locationName + ", " + this.eventRegistration.Country
      }

      return locationName
    },

    image() {
      return this.$options.filters.getEventImageSrc(
        this.eventRegistration.EventImage,
        this.eventRegistration.EventId,
        640
      );
    },
  },

  methods: {
    gotToEvent() {
      this.$router.push({ name: 'EventDetail', params: { 'eventId': this.eventRegistration.EventId } })
    }
  },
};
</script>

<style lang="scss" scoped>
.eventTile {
  height: 100%;
  &:not(.is-compact) {
    @media screen and (min-width: $desktop) {
      display: flex;
      .col-image {
        margin-left: -1px;
        flex-shrink: 1;
        width: 45%;
        max-width: 320px;
      }
      .col-content {
        display: flex;
        flex-direction: column;
        width: 55%;

        div:first-child {
          flex-grow: 1;
        }
      }

      a.link-goto {
        line-height: normal;
        .icon:first-child {
          background-color: $primary-dark;
          color: white;
          font-size: 0.9rem;
          border-radius: 50%;
          text-align: center;
        }
      }
    }
  }
}
</style>